<template>
  <!-- Calendly inline widget begin -->
  <div class="calendly-inline-widget s-expand s-height--expand s-width--expand s-flex-direction--column" data-url="https://calendly.com/spelltosell/online-demo?text_color=1f146d&primary_color=3322b5"></div>
  <!-- Calendly inline widget end -->
</template>

<script>
export default {
  // You can specify layout component name here (default value is 'default')
  layout: 'full',
  mounted () {
    const plugin = document.createElement('script')
    plugin.setAttribute(
      'src',
      '//assets.calendly.com/assets/external/widget.js'
    )
    plugin.async = true
    document.head.appendChild(plugin)
  }
}
</script>
